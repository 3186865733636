<template>
    <div class="daily-report">
        <el-form class="page-search" :inline="true">
            <div class="left">
                <el-form-item>
                    <el-select
                        v-model="searchForm.brandId"
                        placeholder="请选择品牌"
                        filterable
                        clearable
                        @change="querydata()"
                    >
                        <el-option
                            v-for="item in brands"
                            :key="item.id"
                            :label="item.brandName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input
                        v-model="searchForm.skuTitle"
                        placeholder="货品名称"
                        clearable
                        @keyup.native.enter="querydata"
                        @clear="querydata"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                        v-model="sumDate"
                        type="date"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        placeholder="选择日期"
                        @change="querydata"
                    >
                    </el-date-picker>
                </el-form-item>
            </div>
            <div class="right">
                <div class="search-btn">
                    <div class="custom-btn">
                        <el-button
                            type="primary"
                            @click="querydata"
                        >
                            查询
                        </el-button>
                        <el-button @click="reset">重置</el-button>
                    </div>
                </div>
            </div>
        </el-form>
        <div class="page-content">
            <div class="page-handle-btn">
                <span @click="handleClick">
                    <i class="iconfont">警戒值配置</i>
                </span>
            </div>
            <div class="gb-table">
                <el-table
                    ref="table"
                    :data="tableData"
                    height="500"
                    row-key="id"
                >
                    <el-table-column type="index">
                        <template slot="header">
                            <i
                                class="el-icon-s-tools"
                                @click="showColumnSortDia"
                            ></i>
                        </template>
                        <template slot-scope="scope">
                            <span>{{
                                (searchForm.pageIndex - 1) * searchForm.pageSize + scope.$index + 1
                            }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-for="(column, index) in activeColumns"
                        :key="index"
                        :prop="column.prop"
                        :label="column.label"
                        :min-width="column.minWidth"
                        :formatter="column.formatter"
                        :align="column.align ? column.align : 'center'"
                        :show-overflow-tooltip="column.showTip"
                    >
                        <template slot-scope="scope">
                            <span v-if="column.prop =='turnoverValue'" :class="{'mark-red':scope.row.turnoverAlert == 1}">
                                {{ scope.row[column.prop] }}
                            </span>
                            <span v-else-if="column.prop =='sellDays'" :class="{'mark-red':scope.row.sellDaysAlert == 1}">
                                {{ scope.row[column.prop] }}
                            </span>
                            <span v-else>
                                {{ scope.row[column.prop] }}
                            </span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column
                        label="操作"
                        fixed="right"
                        :min-width="150"
                        align="center"
                    >
                        <template slot-scope="scope">
                            <el-button type="text">编辑</el-button>
                            <el-button type="text">删除</el-button>
                        </template>
                    </el-table-column> -->
                </el-table>
                <el-pagination
                    v-if="tableData && tableData.length"
                    :current-page="searchForm.pageIndex"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="searchForm.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                    @current-change="handlesCurrentChange"
                    @size-change="handleSizeChange"
                ></el-pagination>
            </div>
        </div>
        <column-sort-dia
            ref="columnSortDia"
            :columns="columns"
            @column-change="columnChange"
        ></column-sort-dia>
        <el-dialog
            title="配置预警值"
            :visible.sync="showAlarm"
            width="700px"
            center
            top="20vh"
            :modal="true"
        >
            <el-form
                ref="turnoverForm"
                :rules="quitRules"
                :model="turnoverForm"
                label-width="80px"
            >
                <div style="margin-bottom: 20px;">
                    <el-form-item label="品牌" prop="brandId" required>
                        <el-select
                            v-model="turnoverForm.brandId"
                            placeholder="请选择品牌"
                            filterable
                            required
                            @change="queryBrandData()"
                        >
                            <el-option
                                v-for="item in brands"
                                :key="item.id"
                                :label="item.brandName"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>

                周转周期
                <div class="item-class">
                    <div>
                        <div style="margin-top: 20px; margin-left: 20px;">
                            ≥
                        </div>
                        <div style="margin-top: -25px;">
                            <el-form-item prop="startValue" required>
                                <div style="display: flex;">
                                    <span style="margin-right: 10px; color: red;">* </span>
                                    <el-input
                                        v-model="turnoverForm.startValue"
                                        type="number"
                                        placeholder="请输入数字"
                                        style=" display: block; width: 80%;"
                                        size="small"
                                    ></el-input>
                                </div>
                            </el-form-item>
                        </div>
                    </div>

                    <div style=" width: 150px; margin-top: 10px; margin-left: -60px;">
                        <el-form-item prop="conditionValue">
                            <el-select v-model="turnoverForm.conditionValue" size="small">
                                <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div style="margin-top: 20px; margin-left: 60px;">
                        ≤
                    </div>

                    <div style="margin-top: 10px; margin-left: -60px;">
                        <el-form-item prop="endValue">
                            <div style="display: flex;">
                                <span style="margin-right: 10px; color: red;">* </span>
                                <el-input
                                    v-model="turnoverForm.endValue"
                                    placeholder="请输入数字"
                                    style=" display: block; width: 80%;"
                                    size="small"
                                    type="number"
                                ></el-input>
                            </div>
                        </el-form-item>
                    </div>
                </div>
            </el-form>

            <el-form
                ref="sellDaysForm"
                :rules="quitRules"
                :model="sellDaysForm"
                label-width="80px"
            >
                可售天数
                <div class="item-class">
                    <div>
                        <div style="margin-top: 20px; margin-left: 20px;">
                            ≥
                        </div>
                        <div style="margin-top: -25px;">
                            <el-form-item prop="startValue">
                                <div style="display: flex;">
                                    <span style="margin-right: 10px; color: red;">* </span>
                                    <el-input
                                        v-model="sellDaysForm.startValue"
                                        placeholder="请输入数字"
                                        style=" display: block; width: 80%;"
                                        size="small"
                                        type="number"
                                    ></el-input>
                                </div>
                            </el-form-item>
                        </div>
                    </div>

                    <div style=" width: 150px; margin-top: 10px; margin-left: -60px;">
                        <el-form-item prop="conditionValue">
                            <el-select v-model="sellDaysForm.conditionValue" size="small">
                                <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div style="margin-top: 20px; margin-left: 60px;">
                        ≤
                    </div>

                    <div style="margin-top: 10px; margin-left: -60px;">
                        <el-form-item prop="endValue">
                            <div style="display: flex;">
                                <span style="margin-right: 10px; color: red;">* </span>
                                <el-input
                                    v-model="sellDaysForm.endValue"
                                    placeholder="请输入数字"
                                    style=" display: block; width: 80%;"
                                    size="small"
                                    type="number"
                                ></el-input>
                            </div>
                        </el-form-item>
                    </div>
                </div>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitDismisson">确 定</el-button>
                <el-button @click="showAlarm = false">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import columnSortDia from '@/components/columnSortDialog'
import _ from 'lodash'
import utilsTable from '@/utils/table'
import productSvc from 'services/product'
import tradeSvc from 'services/trade'
export default {
    name: 'SupplyChainDailyReport',
    components: { columnSortDia },
    data () {
        return {
            /**
             *  hidden: false, 是否隐藏列
             *  showTip: true, 文字过长悬浮提示
             */
            indicatorsForm: {

            },
            sumDate: null,
            turnoverForm: {
                alarmType: 1,
                conditionValue: 1,
                startValue: null,
                endValue: null,
                brandId: null
            },
            sellDaysForm: {
                alarmType: 2,
                conditionValue: 1,
                startValue: null,
                endValue: null,
                brandId: null
            },
            options: [
                {
                    value: 1,
                    label: '或'
                },
                {
                    value: 2,
                    label: '且'
                }
            ],
            columns: [
                {
                    prop: 'brandName',
                    label: '品牌',
                    hidden: false
                },
                {
                    prop: 'skuTitle',
                    label: '货品',
                    showTip: true,
                    hidden: false,
                    align: 'left'
                },
                {
                    prop: 'stockQuantity',
                    label: '合计库存',
                    hidden: false
                },
                {
                    prop: 'monthStock',
                    label: '平均库存',
                    hidden: false
                },
                {
                    prop: 'weekQuantity',
                    label: '日销（7天）',
                    hidden: false
                },
                {
                    prop: 'sellDays',
                    label: '可售天数',
                    hidden: false
                },
                {
                    prop: 'turnoverValue',
                    label: '周转周期/天',
                    hidden: false
                }
            ],
            quitRules: {
                startValue: [
                    {
                        required: true,
                        message: '请输入初始值',
                        trigger: ['blur', 'change']
                    }
                ],
                endValue: [
                    {
                        required: true,
                        message: '请输入最大值',
                        trigger: ['blur', 'change']
                    }
                ]
            },

            showAlarm: false,
            searchForm: {
                pageIndex: 1,
                pageSize: 20,
                brandId: '',
                sumDate: '',
                skuTitle: ''
            },
            total: null,
            tableData: [],
            brands: []
        }
    },
    computed: {
        activeColumns () {
            return this.columns.filter(item => !item.hidden)
        }
    },
    created () {
        this.sumDate = this.$moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD')
        this.getTableData()
        this.getBrands()
    },
    methods: {
        ...utilsTable,
        async getTableData () {
            if (this.sumDate) {
                this.searchForm.sumDate = this.$moment(this.sumDate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
            }
            const { data = {} } = await tradeSvc.getSupplyChainList(
                this.searchForm
            )
            this.tableData = data.items
            this.total = Number(data.total)
        },
        async getBrands () {
            try {
                const { data = [] } = await productSvc.getBrands()
                // data.unshift({
                //     id: 0,
                //     brandName: '全部'
                // })
                this.brands = data
            } catch (error) {
                console.log(error)
            }
        },
        async  submitDismisson () {
            this.sellDaysForm.brandId = this.turnoverForm.brandId
            console.log('this.sellDaysForm:' + JSON.stringify(this.sellDaysForm))
            let formList = []
            if (!this.turnoverForm.brandId) {
                this.$message.error('请选择品牌')
                return false
            }
            if (!(parseInt(this.turnoverForm.startValue) >= 0) || !(parseInt(this.turnoverForm.endValue) >= 0) || !(parseInt(this.sellDaysForm.startValue) >= 0) || !(parseInt(this.sellDaysForm.endValue) >= 0)) {
                this.$message.error('请填写完整信息1')
                return false
            }
            if (parseInt(this.turnoverForm.startValue) >= 0 && parseInt(this.turnoverForm.endValue) >= 0) {
                formList.push(this.turnoverForm)
            }
            if (parseInt(this.sellDaysForm.startValue) >= 0 && parseInt(this.sellDaysForm.endValue) >= 0) {
                formList.push(this.sellDaysForm)
            }
            if (formList.length === 0) {
                this.$message.error('请填写完整信息')
                return false
            }
            const { data = {} } = await tradeSvc.saveTradestockalarm(
                formList
            )
            this.showAlarm = false
            this.$message({
                message: '提交成功',
                type: 'success'
            })
            this.getTableData()
        },
        async queryBrandData () {
            let brandForm = {
                brandId: this.turnoverForm.brandId
            }
            const { data = {} } = await tradeSvc.getTradestockalarm(
                brandForm
            )
            if (data.length > 0) {
                for (let item of data) {
                    if (item.alarmType == 1) {
                        this.turnoverForm = item
                    } else {
                        this.sellDaysForm = item
                    }
                }
            } else {
                this.turnoverForm.id = null
                this.sellDaysForm.id = null
                this.turnoverForm.startValue = null
                this.turnoverForm.endValue = null
                this.sellDaysForm.startValue = null
                this.sellDaysForm.endValue = null
                this.$refs['turnoverForm'].resetFields()
                this.$refs['sellDaysForm'].resetFields()
                this.turnoverForm.brandId = brandForm.brandId
                console.log('this.turnoverForm:' + this.turnoverForm.brandId)
                this.sellDaysForm.conditionValue = 1
                this.turnoverForm.conditionValue = 1
            }
        },
        handleClick () {
            // this.$message.warning
            this.turnoverForm.id = null
            this.sellDaysForm.id = null
            this.turnoverForm.brandId = null
            this.turnoverForm.startValue = null
            this.turnoverForm.endValue = null
            this.turnoverForm.conditionValue = 1
            this.sellDaysForm.startValue = null
            this.sellDaysForm.endValue = null
            this.sellDaysForm.conditionValue = 1
            if (this.brands.length > 0) {
                this.turnoverForm.brandId = this.brands[0].id
                this.queryBrandData()
                this.$nextTick(() => {
                    this.$refs['turnoverForm'].resetFields()
                    this.$refs['sellDaysForm'].resetFields()
                })
            } else {
                this.$nextTick(() => {
                    this.$refs['turnoverForm'].resetFields()
                    this.$refs['sellDaysForm'].resetFields()
                })
            }
            this.showAlarm = true
        },
        showColumnSortDia () {
            this.$refs.columnSortDia.visible = true
        },
        columnChange (val) {
            this.columns = _.cloneDeep(val)
            this.tableReLoayout('table')
        },
        querydata () {
            if (!this.sumDate) {
                this.searchForm.sumDate = null
            }
            this.searchForm.pageIndex = 1
            this.getTableData()
        },
        handlesCurrentChange (val) {
            this.searchForm.pageIndex = val
            this.getTableData()
        },
        handleSizeChange (val) {
            this.searchForm.pageIndex = 1
            this.searchForm.pageSize = val
            this.getTableData()
        },
        reset () {
            this.sumDate = this.$moment()
                .subtract(1, 'days')
                .format('YYYY-MM-DD')
            this.searchForm = {
                pageSize: 20,
                pageIndex: 1
            }
            this.getTableData()
        }
    }
}
</script>
<style lang="scss" scoped>
.daily-report {
    .el-icon-s-tools {
        cursor: pointer;
    }
}

.item-class {
    display: flex;
}

.mark-red {
    color: red;
}
</style>
